#item{
    letter-spacing: 3px;
}
.navigation-bar {
    justify-content: center;
}

@media (min-width: 992px) {
    .navbar-collapse {
        display: flex;
        justify-content: center;
    }
    .toggle_btn_mobile{
        margin-left: 500px;
    }

    .navbar-nav {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .navbar-nav .nav-link {
        margin: 0 15px;
    }

    .navbar-nav .nav-link img {
        margin: 0 auto;
    }
}
