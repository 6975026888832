#aboutpara {
    background-color: #033D7B;
    color: white;
    padding-top: 200px;
    padding-left: 100px;
    padding-right: 100px;
}

.About_us_content {
     margin-top: 1rem;
}

@media only screen and (max-width: 390px) {
    #aboutpara{
        padding-top: 10px;
    }
    #aboutus_name{
        text-align: center;
    }
}

@media only screen and (max-width: 600px) {
    .About_us_content {
        margin-top: 420px;
    }
    #aboutpara{
        padding-top: 10px;
        padding-left: 30px;
        padding-right: 30px;
        justify-content: 'justify';
    }
    #aboutus_name{
        text-align: center;
    }
}
@media only screen and (max-width: 768px) {
    #aboutimg{
        height: 500px;
    }
}
@media only screen and (max-width: 820px) {
 
    #aboutpara{
        padding-top: 10px;
    }
    #aboutimg{
        height: 500px;
    }
}
@media only screen and (max-width: 1024px) {
 
    #aboutpara{
        padding-top: 10px;
    }
  
}
@media only screen and (max-width: 1280px) {
 
    /* #aboutpara{
        padding-top: 100px;
    } *
    /* #aboutimg{
        height: 400px;
    } */
}