#header_name{
    background-color: black;
    color: white;
}
#title{
    text-align: center;
    letter-spacing: 5px;
}
#Faq_img{
    height: 520px;
    width:100%;
}


#panelpara{
    background-color: #CDEEFC;
    color: black;
    border-radius: 50px;
}
#panelname{
    text-align: center;
}


.css-1086bdv-MuiPaper-root-MuiAccordion-root {
    box-shadow: none !important;
}
.css-1086bdv-MuiPaper-root-MuiAccordion-root::before {
    background-color: white !important;
}
