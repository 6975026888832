
.BACK_footer_img{
    background-image: url('../IMG/footerblue.png');
    /* margin-top: -25px; */
}
::placeholder{
    color: white;

}
#footerImg1 {
    width: 100%;
    height: 550px;
    position: absolute;
}

#footerpara {
    margin: 20px 0;
    position: relative;
    color: white;
    font-family: Century Gothic;
    margin-top: 50px;
    padding: 30px;
    padding-left: 20px;
    padding-right: 80px;
}
#title1{
    font-size: 40px;
}

.bannerform {
    background: #49494980 !important;
    padding: 20px;
    color: white;
    border: 1px solid #FFFFFF !important
}

.bannerinp, .bannertxtarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    background-color:transparent ;
    border-radius: 4px;
}

.bannerbtn {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 20px;
    width: 200px;
}

@media (max-width: 767px) {
    #footerpara, .bannerform {
        text-align: center;
        padding-right: 10px;
    }
    #footerImg1{
        height: 66rem;
    }

}
@media (max-width: 767px) {
    #footerImg1{
        height: 68rem;
    }
}
@media (max-width: 712px) {
    #footerImg1{
        height: 71rem;
    }
}
@media (max-width: 768px) {
    #footerImg1{
        height: 40rem;
    }
}
@media (max-width: 820px) {
    #footerImg1{
        height: 40rem;
    }
}
@media (max-width: 912px) {
    #footerImg1{
        height: 40rem;
       
    }
}
@media (max-width: 540px) {
    #footerImg1{
        height: 58rem;
       
    }
}
@media (max-width: 344px) {
    #footerImg1{
        height: 73rem;
       
    }
}

@media (max-width: 375px) {
    #footerImg1{
        height: 56rem;
       
    }
}
@media (max-width: 412px) {
    #footerImg1{
        height: 73rem;
       
    }
}

