#benefits_card{
    border-radius: 30px;
    
}
.benefits_card{
    margin-top: 60rem;
}
/* #img{
    height: 500px;
} */

/* #backimg{
    position: absolute;
    top: -40px;
    height: 615px;
    width: 400px;
}

#shadow_img{
    position: relative;
    left: 20px;
    top: 320px;
    height: 70px;
    width: 240px;
} */

#img_benefits{
    margin-top: '-30px';
    margin-left: '-18px';
}

@media only screen and (max-width: 600px) {
    #benefits_card{
        justify-content: center;
    }
    .benefits_card{
        margin-top: 26rem;
    }
  }

