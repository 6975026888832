#clickme_btn{
  position: absolute;
  top: 14rem;
  left: 11rem;
  font-size: 7px;
  
}
#send_btn_desktop{
  text-align: end;
}
#header_img {
  position: absolute;
  width: 100%;
}

#logo {
  position: relative;
  top: '100px';
}

#nav {
  position: relative;
  top: 40px;

}

#nav_item {
  position: relative;
  display: inline;
  list-style-type: 'none';
  text-decoration: 'none';
  padding: 10px;
}

@media only screen and (max-width: 600px) {

  #logo1 {
    position: relative;
    width: 90px;
  }

  #nav {
    position: relative;
    top: 0px;

  }
  .send_btn_mobile{
    text-align: end;
  }
  
}
#contact_form {
  opacity: 0.5;
  background-color: rgb(90, 88, 88);
}

#Contact_name {
  color: white;
  font-size: 20px;
}

#form_name {
  color: white;
}

.Input_field {
  color: white;
}

#send_btn {
  background-color: #0d6efd;
  color: white;
  text-align: center;
  border-radius: 20px;
  width: 150px;
  margin-left: 120px;
}
#header_imgs {
  position: relative;
  top: -1rem;
}
::placeholder {
  color: white;

}
#footerImg1 {
  width: 100%;
  height: 550px;
  position: absolute;
}
#footerpara {
  margin: 20px 0;
  position: relative;
  color: white;
  font-family: Century Gothic;
  margin-top: 50px;
  padding: 30px;
  padding-left: 20px;
  padding-right: 80px;
}
#title1 {
  font-size: 40px;
}
.bannerform {
  background: #49494980 !important;
  color: white;
  border: 1px solid #FFFFFF !important
}

.bannerinp,
.bannertxtarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  background-color: transparent;
  border-radius: 4px;
}

.bannerbtn {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 20px;
  width: 200px;
}

@media (max-width: 767px) {

  #footerpara,
  .bannerform {
    text-align: center;
    padding-right: 10px;
  }
  #footerImg1 {
    height: 66rem;
  }

}
@media (max-width: 767px) {
  #footerImg1 {
    height: 68rem;
  }
}

@media (max-width: 712px) {
  #footerImg1 {
    height: 71rem;
  }
}

@media (max-width: 768px) {
  #footerImg1 {
    height: 40rem;
  }
}

@media (max-width: 820px) {
  #footerImg1 {
    height: 40rem;
  }
}

@media (max-width: 912px) {
  #footerImg1 {
    height: 40rem;

  }
}

@media (max-width: 540px) {
  #footerImg1 {
    height: 58rem;

  }
}

@media (max-width: 344px) {
  #footerImg1 {
    height: 73rem;

  }
}

@media (max-width: 375px) {
  #footerImg1 {
    height: 56rem;

  }
}

@media (max-width: 412px) {
  #footerImg1 {
    height: 73rem;

  }
}

/* #buiding_img {
  padding: 10px;
}

.building_frame {
  margin-left: 46rem;
} */