.card_back {
    background-image: url('../IMG/how_its_work_backgroundimg.png');
    height: 58rem;
    padding-top: 50px;
}

#Card_aaplication {
    height: 385px;
    width: 290px;
    border-radius: 50px;
    background-color: #033D7B;
    position: absolute;
    color: white;
    padding-top: 300px;
    text-align: center;
    /* font-family: 'Poppins'; */
    font-size: 20px;
}


#Card_aaplication1 {
    height: 370px;
    width: 340px;
    border-radius: 50px;
    position: relative;
    left: 50px;
}

#Card_aaplication2 {
    height: 370px;
    width: 340px;
    border-radius: 50px;
    position: relative;
    left: 50px;
}

#arrow_img {
    text-align: center;
    margin-top: 150px;
}

#img_arrow {
    margin-top: 150px;
    width: 70px;
}

.img_arrow1 {
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .card_back {
        height: 190rem;
    }

    #img_arrow {
        margin-top: 30px;
        margin-bottom: 20px;
        width: 70px;
        padding: -10px;
    }

    #Card_aaplication1 {
        height: 370px;
        width: 315px !important;
        border-radius: 50px;
        position: relative;
        left: 50px;
    }
    #Card_aaplication2 {
        height: 370px;
        width: 315px !important;
        border-radius: 50px;
        position: relative;
        left: 50px;
    }
    .card_back{
        height: 200rem;
    }
}