.BACK_footer2_img {
  background-image: url('../IMG/Footer2img.png');
  padding-top: 30px;
  padding-bottom: 30px;
}

#icon{
  letter-spacing: 10px;
  text-align: center;
}
 ul,
li {
  color: white;
  text-decoration: none;
  list-style-type: none;
  display: inline;
  padding: 20px;
  text-align: center ;

} 